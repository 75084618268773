import api from '@/common/api';
import { useDispatch, useSelector } from '@/stores';
import { userPermissionActions } from '@/stores/userPermission';
import { CreateUserPermissionPayload, IGroupPermissionResponse } from '@/types/app';
import { openNotification } from '@/utils';
import type { ModalProps } from 'antd';
import { Col, Form, Input, Modal, Row, Spin } from 'antd';
import { useState } from 'react';
import DebouncedSearchSelect from '../DebouncedSearchSelect';

interface IProps extends ModalProps {
  onSubmitSuccess?: () => void;
  onClose?: () => void;
}

const CreateUserPermissionModal = (props: IProps) => {
  const { onClose, onSubmitSuccess, ...rest } = props;
  const [form] = Form.useForm<Pick<CreateUserPermissionPayload, 'name' | 'email' | 'permissionGroupID'>>();

  const dispatch = useDispatch();
  const { user: userInfo } = useSelector((state) => state.profile);
  const { loading } = useSelector((state) => state.userPermission);

  const [searchingPermissionGroup, setSearchingPermissionGroup] = useState(false);

  const handleSubmitForm = (formValues: Pick<CreateUserPermissionPayload, 'name' | 'email' | 'permissionGroupID'>) => {
    dispatch(
      userPermissionActions.createUserPermission({
        ...formValues,
        role: userInfo?.role!,
        redirectURL: `${window?.location?.origin}/reset-password`,
        loginURL: `${window?.location?.origin}/login`,
        countryID: userInfo?.countryID,
      }),
    )
      .unwrap()
      .then(() => {
        onSubmitSuccess?.();
        onClose?.();
      })
      .catch((res) => console.log('error', res.error));
  };

  return (
    <Modal
      {...rest}
      onCancel={() => onClose?.()}
      title="Add User"
      onOk={form.submit}
      okText="Add"
      width={600}
      okButtonProps={{
        loading: loading[userPermissionActions.createUserPermission.typePrefix] || false,
      }}
      centered
    >
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={handleSubmitForm}
        form={form}
        initialValues={{
          name: '',
          email: '',
          permissionGroupID: '',
        }}
        preserve={false}
      >
        <Spin spinning={searchingPermissionGroup || false}>
          <Row>
            <Col xs={24}>
              <Form.Item label="User Name" name="name" rules={[{ required: true, message: 'This field is required!' }]}>
                <Input placeholder="Enter name" maxLength={128} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  { required: true, message: 'This field is required!' },
                  {
                    type: 'email',
                    message: 'Invalid E-mail!',
                  },
                ]}
              >
                <Input placeholder="Enter email" maxLength={128} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    pattern: /^[0-9]+$/,
                    message: 'Please enter on numbers only.',
                  },
                ]}
              >
                <Input placeholder="Enter phone number" maxLength={15} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label="Address" name="address">
                <Input placeholder="Enter address" maxLength={128} />
              </Form.Item>
            </Col>
            <Col xs={24} className="">
              <Form.Item label="Permission Group" name="permissionGroupID" rules={[{ required: true, message: 'This field is required!' }]}>
                <DebouncedSearchSelect
                  showSuggest
                  placeholder="Select permission group"
                  value={form.getFieldValue('permissionGroupID')}
                  loading={searchingPermissionGroup}
                  onChange={(newValue) => form.setFieldsValue({ permissionGroupID: Array.isArray(newValue) ? newValue[0] : newValue })}
                  getOptionsCallback={async (text) => {
                    setSearchingPermissionGroup(true);
                    const res = await api
                      .getGroupPermissions<IGroupPermissionResponse>({
                        keyword: text,
                        role: userInfo?.role!,
                      })
                      .finally(() => setSearchingPermissionGroup(false));

                    return res.data.data
                      .map((item) => ({
                        value: `${item.id}`,
                        label: `${item.name}`,
                      }))
                      ?.filter((item) => item?.label);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Modal>
  );
};

export default CreateUserPermissionModal;
