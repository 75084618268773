import Donutchart from "@/components/common/donutchart";
import { BriefCaseIcon, GlobeIcon, PlusIcon, Users3Icon } from "@/components/common/IconComponents";
import TableWithScrollButtons from "@/components/Shared/TableWithScrollButtons";
import { useSelector } from "@/stores";
import {
  IConstants,
  ICountryConstant,
  IDashboardTopEmployee,
  IEmployee,
  IObject,
} from "@/types/app";
import {
  matchCountryCodeToFlag,
  matchCountryCodeToLabel,
  matchValueToLabel,
  sortedByCountryName,
} from "@/utils";
import { Col, Row, Space, Tag } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import axios from "axios";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";


const DashboardOnboarding= () => {
  const {
    onboardingEmployees,
  } = useSelector((state) => state.dashboard);
  const [series, setSeries] = useState<any>([]);
  useEffect(() => {
    if(onboardingEmployees){
      const inReview=onboardingEmployees?.totalEmployeesRegistrationStatusInReview;
      const inProgress=onboardingEmployees?.totalEmployeesRegistrationStatusInProgress;
      const awating=onboardingEmployees?.totalEmployeesRegistrationStatusAwaitingContract
      +onboardingEmployees?.totalEmployeesRegistrationStatusAwaitingDocuments
      +onboardingEmployees?.totalEmployeesRegistrationStatusAwaitingScreening;
      setSeries([inReview,inProgress,awating,])
    }
  }, [onboardingEmployees]);


  return (
    <div>
      <p className="title">Onboarding</p>
      <Donutchart chartType="onboarding" total={onboardingEmployees?.totalEmployees} label={['In Review', "In Progress", "Awaiting"]} colors={['#165DFF', '#27A376', '#FFD023']} series={series}/>
    </div>
  );
};

export default DashboardOnboarding;
