import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { Badge, Button, Menu, Row, Space, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { content } from '@/common/constants/menus';
import { ROUTES } from '@/common/constants/routes';
import { useDispatch, useSelector } from '@/stores';
import Icon, { CloseOutlined } from '@ant-design/icons';
import { settingsActions } from '@/stores/settings';
import preval from 'preval.macro';
import { pathMatchRegexp } from '@/utils';
import { ExpandIcon } from '../common/IconComponents';
import { CSSProperties, useCallback } from 'react';
import { ReactComponent as Expand } from '@/assets/images/new-icons/expand.svg';
import Logo from '@/assets/images/logo-eor.svg';
import { UserRole } from '@/common/constants/enums';

function MenuComponent(props: any) {
  const { totalMessagesNotSeen } = useSelector((state) => state.messageRooms);
  const pageHeading = ROUTES.find((route) => pathMatchRegexp(route.path, window.location.pathname));

  const { user: userProfile } = useSelector((state) => state.profile);
  // const { userInfo: userProfile } = useSelector((state) => state.auth);

  const { isToggleLeftMenu } = useSelector((state) => state.settings);

  const selectedMenu = content
    ?.filter((item) => userProfile?.role && item?.roles?.includes(userProfile?.role))
    .find((route, index) => window.location.pathname.includes(route.to));
  const selectedChildMenu = selectedMenu?.children?.find((route, index) => window.location.pathname?.includes(route.to));

  const dispatch = useDispatch();

  const generateMenus = useCallback(
    (data: any, hasLabel: boolean) => {
      return data.map((item: any) => {
        if (
          item?.roles?.includes(userProfile?.role) &&
          (item.permission
            ? userProfile?.role !== UserRole.company
              ? userProfile?.userPermission
                ? (!userProfile?.userPermission?.permissionGroup || (userProfile?.userPermission?.permissionGroup &&userProfile?.userPermission?.permissionGroup?.features?.includes(item.permission)))
                && userProfile?.userPermission?.permissionFeatures?.includes(item.permission)
                : false
              : true
            : true) &&
          ((item?.onlyParent && (!userProfile?.parentUserID || userProfile?.parentUserID === '')) || !item?.onlyParent)
        ) {
          if (item?.children?.length > 0 && hasLabel) {
            return (
              <Menu.SubMenu
                key={item.id}
                title={
                  <Row justify="start" align="middle">
                    <Space>
                      {/* <i className={`${item?.icon} mr-1 font-weight-bold`}></i> */}
                      {item?.icon}
                      {item?.label}
                    </Space>
                  </Row>
                }
              >
                {generateMenus(item.children, true)}
              </Menu.SubMenu>
            );
          }
          return (
            <Menu.Item key={item.id}>
              {hasLabel ? (
                <Link to={item.to || '#'} onClick={() => dispatch(settingsActions.toggleLeftMenuAction(false))}>
                  <Row justify="start" align="middle">
                    <Space className="d-flex align-items-center">
                      {/* {item?.icon?<i className={`${item?.icon} mr-1 font-weight-bold`}></i>:null} */}
                      {item?.icon}
                      {item?.label}{' '}
                      {item?.label === 'Messages' && parseInt(totalMessagesNotSeen || '') > 0 ? (
                        // <span
                        //   style={{
                        //     borderRadius: '50%',
                        //     backgroundColor: 'red',
                        //     color: 'white',
                        //   }}
                        //   className="px-1"
                        // >
                        <Badge count={totalMessagesNotSeen}></Badge>
                        // </span>
                      ) : null}
                    </Space>
                  </Row>
                </Link>
              ) : (
                <Tooltip placement="right" title={item?.label}>
                  <Link
                    to={item.to || '#'}
                    // onClick={() => dispatch(settingsActions.toggleLeftMenuAction(false))}
                  >
                    <Row justify="center" align="middle">
                      {item?.icon}
                      {/* <i className={`${item?.icon} mr-2 font-weight-bold`}></i> */}
                    </Row>
                  </Link>
                </Tooltip>
              )}
            </Menu.Item>
          );
        }
      });
    },
    [totalMessagesNotSeen, userProfile],
  );

  return (
    <div>
      <div className={isToggleLeftMenu ? 'offcanvas-active' : ''}>
        <div id="left-sidebar" className={`${isToggleLeftMenu ? 'show' : ''} sidebar p-0 h-100 border-right`}>
          <nav id="left-sidebar-nav" className="sidebar-nav w-100 position-relative">
            <div className={isToggleLeftMenu ? 'd-sm-block d-md-none vivify fadeIn' : 'menu-list-container vivify fadeIn'}>
              <div className="d-flex logo-wrapper border-right">
                <div className="logo-container cursor-pointer"></div>
                <ExpandIcon className="pr-3" onClick={() => dispatch(settingsActions.toggleLeftMenuAction(true))} />
              </div>
              <Button onClick={() => dispatch(settingsActions.toggleLeftMenuAction(false))} className="left-sidebar-close d-sm-none">
                <CloseOutlined />
              </Button>
              <Menu
                selectedKeys={[`${selectedChildMenu?.id || selectedMenu?.id || 1}`]}
                mode="inline"
                defaultOpenKeys={[`${selectedChildMenu?.id ? selectedMenu?.id : -1}`]}
                className="position-relative menu-list border-0 px-3"
                key={`${selectedChildMenu?.id ? selectedMenu?.id : -1}`}
              >
                {generateMenus(content, true)}
              </Menu>
            </div>
            <div
              className={
                !isToggleLeftMenu
                  ? 'd-none  vivify fadeInRight'
                  : 'menu-list-mini-container border-right d-none d-md-block  vivify fadeInLeft'
              }
              // onMouseEnter={()=>dispatch(settingsActions.toggleLeftMenuAction(false))}
            >
              {/* <div
                onClick={() =>
                  dispatch(settingsActions.toggleLeftMenuAction(false))
                }
              >
                <Tooltip placement="right" title="Unfold Menu">
                  <div className="logo-container cursor-pointer"></div>
                </Tooltip>
              </div> */}
              <h5
                className="d-none d-sm-block nav-link pl-4 py-3 mb-0"
                onClick={() => dispatch(settingsActions.toggleLeftMenuAction(!isToggleLeftMenu))}
              >
                {isToggleLeftMenu ? (
                  <Tooltip title="Unfold Menu">
                    {/* <MenuUnfoldOutlined /> */}
                    <Icon
                      style={{ '-webkit-transform': 'scaleX(-1)', transform: 'scaleX(-1)' } as CSSProperties}
                      component={() => <Expand />}
                    />
                  </Tooltip>
                ) : null}
              </h5>
              <Menu selectedKeys={[`${selectedMenu?.id ?? 1}`]} mode="inline" className="menu-list-mini border-0">
                {generateMenus(content, false)}
              </Menu>
            </div>
          </nav>
          {userProfile?.role==="client"&&userProfile?.logo?
          <div className="d-flex logo-wrapper-bottom">
            <div className="logo-container p-2 pb-3 d-flex justify-content-center"><img className=" h-100" src={userProfile?.logo?.fileURL}/></div>
          </div>:null}
          <div className="version">Version: {preval`module.exports = new Date().toLocaleString();`}</div>
        </div>
        <div className="page">
          <Header dataFromSubParent={pageHeading ? pageHeading.pageTitle : 'Detail'} />
          <Outlet />
          <Footer />
        </div>
      </div>
    </div>
  );

  // return (
  //   <>
  //     {!(userProfile?.role === UserRole.admin) ? (
  //       <div>
  //         <div className={isToggleLeftMenu ? "offcanvas-active" : ""}>
  //           <div
  //             id="left-sidebar"
  //             className="sidebar p-0 h-100 border-0 shadow-lg"
  //           >
  //             <nav
  //               id="left-sidebar-nav"
  //               className="sidebar-nav w-100 position-relative"
  //             >
  //               <div
  //                 className={
  //                   isToggleLeftMenu
  //                     ? "d-block d-sm-none vivify fadeIn"
  //                     : "menu-list-container vivify fadeIn"
  //                 }
  //               >
  //                 <div
  //                   onClick={() =>
  //                     dispatch(settingsActions.toggleLeftMenuAction(true))
  //                   }
  //                 >
  //                   <Tooltip placement="right" title="Fold Menu">
  //                     <div className="logo-container cursor-pointer"></div>
  //                   </Tooltip>
  //                 </div>
  //                 <Button
  //                   onClick={() =>
  //                     dispatch(settingsActions.toggleLeftMenuAction(false))
  //                   }
  //                   className="left-sidebar-close d-xl-none"
  //                 >
  //                   <CloseOutlined />
  //                 </Button>
  //                 <Menu
  //                   selectedKeys={[`${selectedMenu?.id ?? 1}`]}
  //                   mode="inline"
  //                   className="menu-list border-0"
  //                 >
  //                   {generateMenus(content, true)}
  //                 </Menu>
  //               </div>
  //               <div
  //                 className={
  //                   !isToggleLeftMenu
  //                     ? "d-none  vivify fadeInRight"
  //                     : "menu-list-mini-container d-none d-sm-block  vivify fadeInLeft"
  //                 }
  //                 // onMouseEnter={()=>dispatch(settingsActions.toggleLeftMenuAction(false))}
  //               >
  //                 <div
  //                   onClick={() =>
  //                     dispatch(settingsActions.toggleLeftMenuAction(false))
  //                   }
  //                 >
  //                   <Tooltip placement="right" title="Unfold Menu">
  //                     <div className="logo-container cursor-pointer"></div>
  //                   </Tooltip>
  //                 </div>
  //                 <Menu
  //                   selectedKeys={[`${selectedMenu?.id ?? 1}`]}
  //                   mode="inline"
  //                   className="menu-list-mini border-0"
  //                 >
  //                   {generateMenus(content, false)}
  //                 </Menu>
  //               </div>
  //             </nav>
  //           </div>
  //           <div className="page">
  //             <Header
  //               dataFromSubParent={
  //                 pageHeading ? pageHeading.pageTitle : "Detail"
  //               }
  //             />
  //             <Outlet />
  //             <Footer />
  //           </div>
  //         </div>
  //       </div>
  //     ) : (
  //       <div>
  //         <Header
  //           dataFromSubParent={
  //             pageHeadingAdmin ? pageHeadingAdmin.pageTitle : "User"
  //           }
  //         />
  //         <Outlet />
  //         <Footer />
  //       </div>
  //     )}
  //   </>
  // );
}

export default MenuComponent;
