import NewMessageModal from '@/components/Modals/NewMessageModal';
import { Button, Input, Form, message, InputRef, Pagination, Tag, Spin, Space, Image } from 'antd';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { EnterOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { IMessage, IMessageRoom } from '@/types/app';
import { appendContent, downloadFileFromURL, matchValueToLabel } from '@/utils';
import { enumOptions } from '@/common/constants/enums';
import Ckeditor from '@/components/common/ckeditor';
import { useDispatch, useSelector } from '@/stores';
import { messageActions } from '@/stores/messages';
import { messageRoomActions } from '@/stores/messageRooms';
import { Link, useParams } from 'react-router-dom';
import { ROUTE_PATH } from '@/common/constants/routes';
import dayjs from 'dayjs';
import { dateTimeFormat, dateTimeFormatNotSecond, pageSizeOptions } from '@/common/constants/format';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ReactComponent as SearchIcon } from '@/assets/images/new-icons/search-icon.svg';
import { ReactComponent as UploadIcon } from '@/assets/images/new-icons/upload-icon.svg';
import CustomUpload from './customUpload';
import FileUploadComponent from './customUpload';
import { UploadFile } from 'antd/lib/upload';

const initialState = {
  id: '',
  userId: '',
  userName: '',
  department: '',
  subject: '',
  message: '',
};

const SendMessage = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // const [messageDetail, setMessageDetail] = useState<IMessageRoom>(initialState);
  const [form] = Form.useForm();
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();
  const ckeditorRef = useRef();
  const { id } = useParams();
  const { constants } = useSelector((state) => state.app);
  const { userInfo } = useSelector((state) => state.auth);

  const {
    data: messages,
    loading: messageLoading,
    pagination: messagePagination,
    detail: messageDetail,
  } = useSelector((state) => state.messages);
  const { data: messageRooms, loading, pagination, detail: messageRoomDetail } = useSelector((state) => state.messageRooms);

  const [fileName, setFileName] = useState('');
  // const [attachmentUpload, setAttachmentUpload] = useState<File>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [replyText, setReplyText] = useState<string>('');

  // ref
  const inputRef = useRef<InputRef>(null);
  // const inputRef = useRef();

  // triggers when file is selected with click

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    {
      inputRef?.current?.input?.click?.();
    }
  };

  // function handleFile(files: any) {
  //   setAttachmentUpload(files[0]);
  //   setFileName(files[0].name);
  // }

  useEffect(function () {
    dispatch(messageRoomActions.getMessageRooms({}));
  }, []);

  const handleSearchSubmit = (e: string) => {
    dispatch(messageRoomActions.getMessageRooms({ keyword: e }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(
      messageRoomActions.getMessageRooms({
        page,
        limit: pageSize,
      }),
    );
  };

  const handleOpenModal = (e: boolean) => {
    setIsModalOpen(e);
  };

  const getSuccessFileList = () => {
    return fileList
      .filter((file) => file?.status === 'done')
      .map((file) => (file.originFileObj && file.originFileObj instanceof File ? file.originFileObj : null))
      .filter((file) => file !== null);
  };

  const transformBeforeSubmit = (e: any) => {
    return {
      ...e,
      chatRoomID: id,
      attachments: getSuccessFileList(),
    };
  };

  // const handleReplySubmit = async (e: { message: string }) => {
  const handleReplySubmit = (e: any) => {
    form.resetFields();
    const result = transformBeforeSubmit(e);
    // if (e?.message) appendContent(e);
    dispatch(messageActions.createMessage(result))
      .unwrap()
      .then((resp) => {
        dispatch(messageRoomActions.sortChatRoomsByNewestMessage(resp?.data?.data));

        const element = document.getElementById('message-content');
        if (element) element.scrollTop = element.scrollHeight;
        dispatch(messageActions.getMoreMessages({ chatRoomID: id }));
      });
    console.log('File name before', fileName);
    setFileList([]);
    // setAttachmentUpload(undefined);
    setFileName('');
    setReplyText('');
  };

  // const getMessageDetail = (id: string) => {
  //   dispatch(messageRoomActions.getMessageRoom({id}));
  //   dispatch(messageActions.getMoreMessages({chatRoomID:id}))
  // };

  const onCkeditorChange = (e: any) => {
    form.setFieldValue('message', e);
  };

  useEffect(
    function () {
      if (id) {
        dispatch(messageRoomActions.getMessageRoom({ id }));
        dispatch(messageActions.getMoreMessages({ chatRoomID: id }));
        dispatch(
          messageRoomActions.markAsSeenMessageRoom({
            id,
          }),
        ).then(() => {
          dispatch(messageRoomActions.getMessageRoomStatistics({}));
          dispatch(messageRoomActions.getMessageRooms({}));
        });
      }
    },
    [id],
  );

  useEffect(() => {
    form.resetFields();
  }, [messageRoomDetail]);

  const fetchMoreMessages = () => {
    if (messagePagination.page < messagePagination.totalPage) {
      dispatch(
        messageActions.getMoreMessages({
          chatRoomID: id,
          page: messagePagination.page + 1,
        }),
      );
    }
  };

  const fetchMoreMessageRooms = () => {
    if (pagination.page < pagination.totalPage) {
      dispatch(
        messageRoomActions.getMoreMessageRooms({
          page: pagination.page + 1,
        }),
      );
    }
  };

  // const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
  //   e.preventDefault();
  //   if (e.target.files && e.target.files[0]) {
  //     handleFile(e.target.files);
  //   }
  // };

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    dispatch(messageRoomActions.getMessageRooms({ keyword: value }));
  };

  console.log('Message room', messageRooms);

  console.log('file name', fileName);

  return (
    <div id="send-message" className="container-fluid">
      <Spin tip="Loading" size="large" spinning={loading[messageRoomActions.getMessageRooms.typePrefix]}>
        <div className="d-flex justify-content-between" style={{}}>
          <div className="card-header" style={{ marginBottom: '20px', marginTop: '20px', padding: 0 }}>
            <div className="" style={{ color: '#1E1D1D', fontSize: '24px', lineHeight: '32px', fontWeight: 700 }}>
              Message
            </div>
          </div>
          <Button
            className="d-flex align-items-center"
            style={{ marginBottom: '20px', marginTop: '20px' }}
            type="primary"
            onClick={() => handleOpenModal(true)}
          >
            <PlusOutlined />
            Add New
          </Button>
        </div>
        <div className="bg-white rounded" style={{ height: "calc(100vh - 150px), overflow: 'auto" }}>
          <div className="card-body row border-top p-0 d-flex flex-column flex-xl-row flex-lg-row">
            <div className="col-lg-4 border-bottom p-0 message-list">
              <div className="col-md-12 sticky-search">
                <div className="col-md-12 sticky-search d-flex" style={{ padding: '15px 24px' }}>
                  <Input
                    placeholder="Search..."
                    allowClear
                    prefix={<SearchIcon />}
                    className="custom-search-input"
                    value={searchValue}
                    onChange={handleChangeValue}
                  />
                </div>
              </div>
              {messageRooms && messageRooms.length ? (
                <InfiniteScroll
                  // scrollableTarget="scrollableDiv"
                  // below props only if you need pull down functionality

                  // className="d-flex flex-column-reverse"
                  hasMore={(messageRooms?.length || 0) < pagination?.total}
                  dataLength={messageRooms?.length || 0}
                  // height={650}
                  style={{ height: 'calc(100vh - 230px)' }}
                  // pullDownToRefreshThreshold={1}
                  // inverse={true}
                  // scrollableTarget="message-content"
                  next={fetchMoreMessageRooms}
                  // endMessage={
                  //   <p style={{ textAlign: "center" }}>
                  //     <b>You have seen it all</b>
                  //   </p>
                  // }
                  loader={<Spin tip="Loading" size="small" spinning={loading[messageRoomActions.getMoreMessageRooms.typePrefix]}></Spin>}
                >
                  {messageRooms?.map((item) => (
                    <Link
                      to={`${ROUTE_PATH.MESSAGES}/${item.id}`}
                      className=""
                      // onClick={() => getMessageDetail(item.id)}
                      key={item.id}
                    >
                      <div className={`message-hover p-4 border-top ${item.id === id ? 'message-selected' : ''}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          {/* <b className="text-blue">{item.email}</b> */}
                          {/* <span className="small">{item.sentTime}</span> */}
                        </div>
                        <b className="mr-2" style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 600 }}>
                          {item.subject}
                        </b>
                        {parseInt(item?.totalMessagesNotSeen || '') > 0 ? (
                          <span
                            style={{
                              borderRadius: '50%',
                              backgroundColor: 'red',
                              color: 'white',
                              fontSize: '12px',
                              fontWeight: 'bold',
                            }}
                            className="px-1"
                          >
                            {item?.totalMessagesNotSeen}
                          </span>
                        ) : null}

                        <div
                          className="d-flex align-items-center"
                          style={{ fontSize: '12px', lineHeight: '18px', fontWeight: 500, color: '#424141' }}
                        >
                          <p className="mb-0 mr-1">
                            {/* Department:  */}
                            {matchValueToLabel(constants?.departments || [], item.department || '')}
                          </p>
                          <div>|</div>

                          <div className="ml-1">
                            {item?.participant?.forename && item?.participant?.surname
                              ? `${item.participant.forename} ${item.participant.surname}`
                              : item?.participant?.username || item?.participant?.email}
                          </div>
                        </div>
                        <div
                          style={{
                            color: '#8A8988',
                            fontSize: '12px',
                            lineHeight: '18px',
                            fontWeight: 400,
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item?.latestMessage?.message}
                        </div>
                      </div>
                    </Link>
                  ))}
                </InfiniteScroll>
              ) : (
                <span className="p-4">No message</span>
              )}
            </div>
            <div className="border-left col-lg-8 border border-secondary shadow-sm bg-white">
              {messageRoomDetail?.id && id ? (
                <div>
                  <div className="row">
                    <div className="" style={{ marginLeft: '10px', marginTop: '5px' }}>
                      {/* <b>Department: </b> */}
                      {/* <div style={{ marginLeft: '16px', fontSize: '24px', lineHeight: '24px', fontWeight: 600 }}>
                        {' '}
                        {matchValueToLabel(constants?.departments || [], messageRoomDetail.department || '')}
                      </div> */}
                    </div>
                  </div>

                  <div className="col-lg-8">
                    <div className="" style={{ fontSize: '16px', lineHeight: '24px', fontWeight: 600 }}>
                      <div style={{ marginLeft: '5px' }}>{messageRoomDetail.subject}</div>
                    </div>
                  </div>

                  <div className="border-bottom d-flex" style={{ color: '#8A8988', fontSize: '12px', lineHeight: '18px', fontWeight: 500 }}>
                    {/* <b>Department: </b> */}
                    <div className="mr-1" style={{ marginLeft: '12px' }}>
                      {matchValueToLabel(constants?.departments || [], messageRoomDetail.department || '')}
                    </div>
                    <div>|</div>
                    {messageRoomDetail?.participant && messageRoomDetail?.participant?.id !== '' && (
                      <div className="ml-1">
                        {/* {messageRoomDetail.participant?.forename} {messageRoomDetail.participant?.surname} */}
                        {messageRoomDetail?.participant?.name ||
                          messageRoomDetail?.participant?.username ||
                          messageRoomDetail?.participant?.email}
                      </div>
                    )}
                  </div>

                  <div className="col-md-12 p-0">
                    <div
                      id="scrollableDiv"
                      style={{
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column-reverse',
                      }}
                      className="p-3 border-bottom"
                    >
                      <InfiniteScroll
                        // scrollableTarget="scrollableDiv"
                        // below props only if you need pull down functionality

                        className="d-flex flex-column-reverse pl-2 pr-2"
                        hasMore={(messages?.length || 0) < messagePagination?.total}
                        dataLength={messages?.length || 0}
                        // height={'80vh'}
                        style={{ height: 'calc(100vh - 370px)' }}
                        pullDownToRefreshThreshold={1}
                        inverse={true}
                        // scrollableTarget="message-content"
                        next={fetchMoreMessages}
                        // endMessage={
                        //   <p style={{ textAlign: "center" }}>
                        //     <b>You have seen it all</b>
                        //   </p>
                        // }
                        loader={
                          <Spin tip="Loading" size="small" spinning={messageLoading[messageActions.getMoreMessages.typePrefix]}></Spin>
                        }
                      >
                        {messages?.map((item) => (
                          // <div key={item.id} className={item.sender?.id === userInfo?.id ? 'text-end' : 'text-align-left'}>
                          <span
                            key={item.id}
                            style={{
                              textAlign: item.sender?.id === userInfo?.id ? 'right' : 'left',
                              // width: item.sender?.id === userInfo?.id ? '500px' : '500px',
                              display: 'inline-block',
                              // width: '50%',
                              // width: 'fit-content',
                              maxWidth: '50%',
                              alignSelf: item.sender?.id === userInfo?.id ? 'flex-end' : 'flex-start',
                            }}
                          >
                            <div className="">
                              {item.sender?.id === userInfo?.id ? (
                                ''
                              ) : (
                                <small style={{ fontSize: '12px', lineHeight: '18px', fontWeight: 700, color: '#1E1D1D' }}>
                                  {/* {item?.sender?.email} */}

                                  {/* {item?.sender?.name}{' '} */}
                                  {item?.sender?.name && item?.sender?.name.trim() !== ''
                                    ? item.sender.name
                                    : item?.sender?.username || item?.sender?.email}
                                </small>
                              )}
                              <small style={{ marginLeft: '5px', fontSize: '12px', lineHeight: '18px', fontWeight: 400, color: '#8A8988' }}>
                                {dayjs(item?.createdAt).format(dateTimeFormatNotSecond)}
                              </small>
                            </div>

                            {item?.message ? (
                              <div style={{ marginTop: '10px' }}>
                                {item?.message?.split('\n').map(
                                  (item2, index) =>
                                    item2.trim() !== '' && ( // Kiểm tra item2 không phải là chuỗi rỗng
                                      <span
                                        key={index} // Luôn thêm key khi lặp qua một danh sách
                                        style={{
                                          border: '0.5px solid #E4E3E2',
                                          borderRadius: '8px',
                                          padding: '8px 12px',
                                          marginBottom: '10px',
                                          backgroundColor: '#FBFAF8',
                                          fontSize: '12px',
                                          lineHeight: '18px',
                                          fontWeight: 400,
                                          color: '#1E1D1D',
                                          display: 'inline-block',
                                          width: 'fit-content',
                                        }}
                                      >
                                        {item2}
                                      </span>
                                    ),
                                )}
                              </div>
                            ) : null}

                            {item?.attachments?.map((attachment, index) => (
                              <div key={index} style={{ marginTop: '10px' }}>
                                {/(\.jpg|\.jpeg|\.png|\.gif)$/i.test(attachment.fileURL) ? (
                                  <img
                                    src={attachment.fileURL}
                                    alt={attachment?.metadata?.name || 'Attachment'}
                                    style={{
                                      maxWidth: '100%',
                                      borderRadius: '8px',
                                      marginBottom: '5px',
                                    }}
                                  />
                                ) : (
                                  <Tag style={{ width: '100%' }}>
                                    <b
                                      className="cursor-pointer"
                                      style={{
                                        width: '100%',
                                        display: 'inline-block',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                      onClick={() => downloadFileFromURL(attachment.fileURL || '', attachment.metadata?.name)}
                                    >
                                      {attachment.metadata?.name}
                                    </b>
                                  </Tag>
                                )}
                              </div>
                            ))}
                          </span>
                        ))}
                      </InfiniteScroll>
                    </div>
                  </div>
                  <div className="col-md-12 p-2" style={{ alignItems: 'center' }}>
                    <Form form={form} onFinish={handleReplySubmit} className="p-3 d-flex align-items-center">
                      <div className="d-flex" style={{ flex: 1 }}>
                        <div className="d-flex" style={{ justifyContent: 'center', alignItems: 'center', marginRight: '5px' }}>
                          <CustomUpload condition="send" fileList={fileList} setFileList={setFileList} />
                        </div>
                        <div style={{ flex: 1 }}>
                          {' '}
                          <Form.Item name="message" style={{ width: '100%', marginBottom: '0px' }}>
                            <Input.TextArea
                              rows={3}
                              placeholder="Reply here..."
                              value={replyText}
                              onChange={(e) => setReplyText(e.target.value)}
                              onKeyDown={(e: any) => {
                                if (e?.keyCode == 10 || e?.keyCode == 13) {
                                  if (!e?.shiftKey) {
                                    handleReplySubmit({ message: replyText });
                                  }
                                }
                              }}
                              maxLength={500}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      <Button
                        disabled={
                          loading[messageActions.createMessage.typePrefix] || (replyText.trim() === '' && getSuccessFileList().length <= 0)
                        }
                        htmlType="submit"
                        className="float-right p-3 ml-2 justify-content-center d-flex align-items-center"
                        style={{
                          flexShrink: 0,
                          color:
                            loading[messageActions.createMessage.typePrefix] ||
                            (replyText.trim() === '' && getSuccessFileList().length <= 0)
                              ? '#FDFCFB'
                              : 'white',

                          fontSize: '14px',
                          lineHeight: '20px',
                          fontWeight: 600,
                          backgroundColor:
                            loading[messageActions.createMessage.typePrefix] ||
                            (replyText.trim() === '' && getSuccessFileList().length <= 0)
                              ? '#D3D1CF'
                              : 'black',
                        }}
                      >
                        Send
                      </Button>
                    </Form>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <NewMessageModal
          isOpen={isModalOpen}
          setIsOpen={(e: boolean) => handleOpenModal(e)}
          hideTo={userInfo?.role === 'client'}
          role={userInfo?.role}
        />
      </Spin>
    </div>
  );
};

export default SendMessage;
