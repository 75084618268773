import { IDocument, IDocumentQuery } from '@/types/app';
import { Button, Form, Grid, Input, Space, Spin, Tag, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FileTwoTone, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { downloadFileFromURL, getBreakPoint, matchCountryCodeToFlag, matchCountryCodeToLabel, matchValueToLabel } from '@/utils';
import { dateFormat, pageSizeOptions } from '@/common/constants/format';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { useEffect, useState } from 'react';
import UploadInvoiceModal from '@/components/Modals/UploadInvoiceModal';
import dayjs from 'dayjs';
import { enumOptions, UserRole } from '@/common/constants/enums';
import { useDispatch, useSelector } from '@/stores';
import { invoicesActions } from '@/stores/invoices';
import UploadDocumentModal from '@/components/Modals/UploadDocumentModal';
import Paragraph from 'antd/es/typography/Paragraph';
import { DownloadIcon, EyeIcon, SearchIcon } from '@/components/common/IconComponents';
import { SorterResult } from 'antd/es/table/interface';

const { useBreakpoint } = Grid;

const Invoices = () => {
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');
  const { constants, countries } = useSelector((state) => state?.app);
  const { data, pagination, filter, loading } = useSelector((state) => state?.invoices);
  const { userInfo } = useSelector((state) => state?.auth);

  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [seeAllContentList, setSeeAllContentList] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    return () => {
      dispatch(invoicesActions.resetFilter());
    };
  }, []);

  useEffect(() => {
    dispatch(invoicesActions.getDocuments(filter));
  }, [filter]);

  const onChangeFilter = (value: IDocumentQuery) => {
    dispatch(
      invoicesActions.setFilter({
        ...filter,
        ...value,
      }),
    );
  };

  const columns: ColumnsType<IDocument> = [
    {
      title: 'Actions',
      key: 'action',
      fixed: 'left',
      align: 'center',
      width: 100,
      render: (_, record) => (
        record?.attachment?.fileURL? <Space>
          <Tooltip title="View Invoice">
            <Button
              type="text"
              size="small"
              onClick={() => {
                window.open(record?.attachment?.fileURL);
              }}
            >
              <EyeIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download">
            <Button
              type="text"
              size="small"
              onClick={() => {
                downloadFileFromURL(record?.attachment?.fileURL, record?.attachment?.metadata?.name);
              }}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        </Space>:null
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 200,
      sorter: true,
      render: (item) =>
        item && (
          <Space>
            <img src={item?.flag} width="16" height="12" alt={`${item?.name}`} className="mr-1" />
            {item?.name}
          </Space>
        ),
    },
    {
      title: 'File Name',
      dataIndex: ['attachment', 'metadata', 'name'],
      key: 'fileName',
      width: 200,
      sorter: true,
      render: (value, record) => {
        return (
          <>
            {value ? (
              <Paragraph
                className="mb-0"
                ellipsis={{
                  rows: 1,
                  // expandable: false,
                  // tooltip: value,
                }}
                title={value}
              >
                {value}
              </Paragraph>
            ) : null}
          </>
        );
      },
    },
    {
      title: 'Date Uploaded',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: true,
      render: (val) => dayjs.unix(val).format(dateFormat),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'dateUploaded',
      width: 200,
      sorter: true,
      render: (value, elm) => {
        return (
          <div
            className={`${seeAllContentList.includes(elm.id || '') ? '' : 'text-truncate'} cursor-pointer`}
            onClick={() =>
              seeAllContentList.includes(elm.id || '')
                ? setSeeAllContentList(seeAllContentList.filter((item) => item !== elm.id))
                : setSeeAllContentList([...seeAllContentList, elm.id || ''])
            }
          >
            {value}
          </div>
        );
      },
    },
    // {
    //   title: 'Document Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   width: 200,
    //   sorter: true,
    //   render: (val) =>
    //     val ? <Tag className="new-hire-tag rounded text-uppercase">{matchValueToLabel(constants?.documentTypes || [], val)}</Tag> : null,
    // },
    {
      title: 'Client Name',
      dataIndex: 'user',
      key: 'clientName',
      width: 200,
      sorter: true,
      render: (user) => {
        return (
          <>
            {user?.id?
              <Paragraph
                className="mb-0"
                ellipsis={{
                  rows: 1,
                  // expandable: false,
                  // tooltip: user,
                }}
                title={user?.name || user?.username}
              >
                {user?.name || user?.username}
              </Paragraph>
            :null}
          </>
        );
      },
    },
  ];

  const handleOpenModal = (e: boolean) => {
    setIsModalOpen(e);
  };

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    onChangeFilter({ keyword });
  };

  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IDocument> | SorterResult<IDocument>[],
  ) => {
    dispatch(
      invoicesActions.getDocuments({
        ...filter,
        page: pagination.current,
        limit: pagination.pageSize,
        keyword: searchText,
        sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
      }),
    );
  };

  const handleSubmitDocument = (values: IDocument) => {
    let newValues: IDocument = { ...values, type: 'invoice' };

    dispatch(invoicesActions.createDocument(newValues))
      .unwrap()
      .then(() => {
        dispatch(invoicesActions.getDocuments(filter));
        setIsModalOpen(false);
      });
  };

  return (
    <div className="container-fluid pt-4">
      <Spin tip="Loading" size="large" spinning={loading[invoicesActions.getDocuments.typePrefix]}>
        <div className="d-flex justify-content-between">
          <p className="page-listing-title">Invoices</p>
        </div>
        <div className="bg-white rounded">
          <div className="card-header d-flex justify-content-between">
            <Input
              prefix={<SearchIcon className="mr-0" />}
              placeholder="Search"
              onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
              className="w-100 mr-2 page-listing-search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            {userInfo?.role === UserRole.company ? (
              <Button className="d-flex align-items-center" type="primary" onClick={() => handleOpenModal(true)}>
                <PlusOutlined />
                Import
              </Button>
            ) : null}
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <TableWithScrollButtons
                columns={columns}
                dataSource={data}
                size="small"
                scroll={{ x: 800 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: pagination.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
              />
            </div>
          </div>
        </div>
        {isModalOpen && (
          <UploadDocumentModal
            open={isModalOpen}
            destroyOnClose={true}
            onCancel={() => setIsModalOpen(false)}
            onSubmit={handleSubmitDocument}
            confirmLoading={loading[invoicesActions?.createDocument.typePrefix]}
            countries={countries}
            hideDocumentType={true}
            isShowInputClient
          />
        )}
      </Spin>
    </div>
  );
};

export default Invoices;
