import { ICountryConstant, IDocument, IDocumentQuery } from '@/types/app';
import { Button, Form, Input, message, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import { useRef, useState, DragEvent, ChangeEvent, useEffect } from 'react';
import type { InputRef, ModalProps, UploadFile } from 'antd';
import { useSelector } from '@/stores';
import { constantsToOptions } from '@/utils';
import SearchEmployees from '../SearchEmployees';
import { ReactComponent as IconUpload } from '@/assets/images/new-icons/icon-upload.svg';
import { ReactComponent as IconAttachment } from '@/assets/images/new-icons/attachment-icon.svg';
import { ReactComponent as IconChecked } from '@/assets/images/new-icons/checked-icon.svg';
import SearchUsers from '../SearchUsers';
import { UserRole } from '@/common/constants/enums';
import Dragger from 'antd/es/upload/Dragger';
import { RcFile } from 'antd/es/upload';
import { InboxOutlined } from '@ant-design/icons';
const MAX_FILE_SIZE = 50;

interface IProps extends ModalProps {
  data?: IDocument | null;
  onSubmit: (data: IDocumentQuery) => void;
}

const FilterDocumentModal = (props: IProps) => {
  const {
    data,
    onSubmit,
    title = 'Filter',
    ...rest
  } = props;
  const [form] = Form.useForm();
  const { constants, countries } = useSelector((state) => state?.app);
  const defaultCountryID = countries.find((item) => item?.countryCode === 'GB')?.id;

  // const initState = {
  //   countryID: defaultCountryID,
  // };

  // useEffect(() => {
  //   if (data) {
  //     form.setFieldsValue(data);
  //   } else {
  //     form.setFieldsValue(initState);
  //   }
  // }, [data]);

  const handleSubmitForm = (value: IDocumentQuery) => {
    let newValue = { ...value };
    onSubmit(newValue);
    // form.resetFields();
  };

  return (
    <Modal {...rest} title={title} onOk={form.submit} okText="Filter" width={600} centered>
      <Form labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" onFinish={handleSubmitForm} form={form} preserve={false}>
        <div className="row">

            <div className={`${'col-lg-12'}`}>
              <Form.Item
                label="Country"
                name="countryIDs"
              >
                <Select
                  placeholder="Select country"
                  filterOption={(input: string, option: any) => {
                    return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
                  }}
                  showSearch
                  mode="multiple"
                  allowClear
                >
                  {countries?.map((item: any, index: number) => {
                    return (
                      <Select.Option key={item?.id} value={item?.id} name={item?.name}>
                        <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
        </div>
      </Form>
    </Modal>
  );
};

export default FilterDocumentModal;
