import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IDocument, IEmployee, IEmployeeTimesheetsListTable } from '@/types/app';
import { Button, Card, Col, DatePicker, Divider, Form, Grid, Input, InputNumber, Radio, Row, Select, Space, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { dateFormat, pageSizeOptions } from '@/common/constants/format';
import { useDispatch, useSelector } from '@/stores';
import { constantsToOptions, downloadFileFromURL, getBreakPoint, openNotification, settingsToOptions } from '@/utils';
import { newHireActions } from '@/stores/newHire';
import axios from 'axios';
import { RangePickerProps } from 'antd/es/date-picker';
import { FileTwoTone, LeftOutlined, DownloadOutlined } from '@ant-design/icons';
import UploadDocumentModal from '@/components/Modals/UploadDocumentModal';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { ColumnsType } from 'antd/es/table';
import { uploadS3 } from '@/common/api/s3';
import { employeeActions } from '@/stores/employee';
import { ArrowLeftIcon } from '@/components/common/IconComponents';
import SearchUsers from '@/components/SearchUsers';
import SearchEndClients from '@/components/SearchEndClients';
import NewNoteModal from '@/components/Modals/NewNoteModal';

const { useBreakpoint } = Grid;

const NewHireForm = (props: any) => {
  const { constants, countries, settings } = useSelector((state) => state.app);
  const { detail, loading, othersDocuments } = useSelector((state) => state.newHire);
  const { userInfo } = useSelector((state) => state.auth);
  const defaultCountryID = countries.find((item) => item?.countryCode === 'GB')?.id;
  //     setDefaultCountryID(result);

  const initialState: IEmployee = useMemo(
    () => ({
      title: 'mr',
      forename: '',
      surname: '',
      email: '',
      dob: '',
      employeeNationalityID: defaultCountryID,
      countryOfHireID: defaultCountryID,
      needVisa: true,
      // clientName: "",
      jobTitle: '',
      jobDescription: '',
      currency: 'EUR',
      payFrequency: 'hourly',
      // startDate: 0,
      contractType: 'ongoing',
      requireHealthInsurance: true,
      // registrationStatus: 'in_review',
      status: 'in_review',
      maritalStatus: 'single',
      workType: 'full_time',
    }),
    [countries],
  );

  const [requestData, setRequestData] = useState<IEmployee>(initialState);
  // const [defaultCountryID, setDefaultCountryID] = useState<string>();
  const [contractType, setContractType] = useState<string>(requestData.contractType);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [documentPagination, setDocumentPagination] = useState<any>({
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  });

  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');

  const [documentLoading, setDocumentLoading] = useState<boolean>(false);

  const columns: ColumnsType<IDocument> = [
    {
      title: 'Actions',
      key: 'action',
      fixed: 'left',
      width: 120,
      render: (_, record: any) => (
        <Space size="middle">
          <Tooltip title="Open Document">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                window.open(record?.attachment?.fileURL || record?.fileURL);
              }}
            >
              <FileTwoTone />
            </Button>
          </Tooltip>
          <Tooltip title="Download">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                downloadFileFromURL(record?.attachment?.fileURL, record?.attachment?.metadata?.name);
              }}
            >
              <DownloadOutlined />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Date Uploaded',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (val) => (val ? dayjs.unix(val).format(dateFormat) : dayjs().format(dateFormat)),
    },
    {
      title: 'File Name',
      dataIndex: ['attachment', 'metadata', 'name'],
      key: 'fileName',
      render: (val, elm: any) => (val ? val : elm?.metadata?.name),
    },
    // {
    //   title: "Document Type",
    //   dataIndex: "type",
    //   key: "type",
    //   align: "center",
    //   render: (val) => matchValueToLabel(constants?.documentTypes || [], val),
    // },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (val) => val,
    },
  ];

  const [form] = Form.useForm();
  const dispatch: any = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  const [startDate, setStartDate] = useState<number | undefined>(requestData.startDate);
  const [endDate, setEndDate] = useState<number | undefined>(requestData.endDate);

  const disabledStartDate: RangePickerProps['disabledDate'] = (current) => {
    if (endDate) {
      return current && current > dayjs(endDate).startOf('day');
    }
    if(current.valueOf()>0){
      return false;
    }
    else return true;
  };

  const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
    if (startDate) {
      return current && current < dayjs(startDate).endOf('day');
    }
    if(current.valueOf()>0){
      return false;
    }
    else return true;
  };

  const disabledBirthDate = (current: any) => {
    return current && current > dayjs().startOf('day');
  };

  const transformBeforeSubmit = (e: IEmployee) => {
    console.log('transformBeforeSubmit', e);
    return {
      ...e,
      id,
      forename: e.forename?.trim?.(),
      surname: e.surname?.trim?.(),
      name: e.forename?.trim?.() + ' ' + e.surname?.trim?.(),
      role: userInfo?.role,
      startDate: e.startDate ? dayjs(e.startDate).unix() : 0,
      endDate: e.endDate ? dayjs(e.endDate).unix() : 0,
      // dob: e.dob ? dayjs(e.dob).unix() : 0,
      // registrationStatus: e?.registrationStatus || 'in_review',
      payRate: e?.payRate ? Number(Number(e?.payRate)?.toFixed(2)) : undefined,
      bonuses: e?.bonuses ? Number(Number(e?.bonuses)?.toFixed(2)) : undefined,
    };
  };

  const handleSubmitForm = async (e: IEmployee) => {
    const result = transformBeforeSubmit(e);
    console.log('handleSubmitFormhandleSubmitForm', result);

    if (props?.create) {
      await dispatch(newHireActions.createNewHire(result));
    } else {
      await dispatch(newHireActions.updateNewHire(result));
      await dispatch(newHireActions.getEmployeeOthers({ id: id, role: userInfo?.role }));
    }
  };

  const handleOpenModal = (e: boolean) => {
    setIsModalOpen(e);
  };

  const handleSubmitDocument = async (values: IDocument) => {
    setDocumentLoading(true);
    // let newValues: IDocument = { ...values, type: "others" };
    if (values?.attachmentUpload && values?.attachmentUpload instanceof File) {
      const { success, data: attachment } = await uploadS3(values?.attachmentUpload, 'others');

      if (success) {
        const result = [{ ...values, type: 'others', attachment: attachment }, ...documents];
        setDocumentLoading(false);
        setDocuments(result);
        form.setFieldValue('documents', result);
        openNotification('success', 'Upload successfully!');
      } else openNotification('error', 'Upload Failed!');
    }
    setDocumentLoading(false);

    setIsModalOpen(false);
  };

  useEffect(
    function () {
      if (id && !props?.create) {
        dispatch(newHireActions.getNewHire({ id: id, role: userInfo?.role }));
        dispatch(newHireActions.getEmployeeOthers({ id: id, role: userInfo?.role }));
      } else {
        setRequestData({ ...initialState, employeeNationalityID: defaultCountryID, countryOfHireID: defaultCountryID });
        setDocuments([]);
        setDocumentPagination({
          total: 0,
          page: 0,
          limit: 10,
          totalPage: 0,
        });
      }
    },
    [id, initialState],
  );

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(
      newHireActions.getEmployeeOthers({
        page,
        limit: pageSize,
        role: userInfo?.role,
        id,
      }),
    );
  };

  useEffect(
    function () {
      if (detail && !props?.create) setRequestData(detail);
      else setRequestData(initialState);
    },
    [detail],
  );

  useEffect(
    function () {
      if (id && othersDocuments?.data?.length) {
        setDocuments(othersDocuments?.data);
        setDocumentPagination(othersDocuments?.pagination);
      } else {
        setDocuments([]);
        setDocumentPagination({
          total: 0,
          page: 0,
          limit: 10,
          totalPage: 0,
        });
      }
    },
    [othersDocuments],
  );

  useEffect(() => {
    form.resetFields();
    setContractType(requestData.contractType);
    setStartDate(requestData.startDate);
    setEndDate(requestData.endDate);
  }, [requestData]);

  return (
    <div className="container-fluid no-height">
      <Spin
        tip="Loading"
        size="large"
        spinning={loading[newHireActions.getNewHire.typePrefix] || false}
      >
        <Form
          colon={false}
          labelAlign="left"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 16 }}
          labelWrap
          layout="horizontal"
          initialValues={requestData}
          onFinish={handleSubmitForm}
          form={form}
          className="request-new-hire-form position-relative general-information"
        >
          <Form.Item noStyle name="registrationStatus"></Form.Item>
          <div className="d-flex justify-content-between align-items-center py-3">
            <div className="d-flex justify-content-between align-items-center page-listing-title mb-0">
              <ArrowLeftIcon className="mr-2" onClick={navigateBack} />
              <span>Edit Request</span>
            </div>
            <Space>
              <Button ghost type="primary" onClick={navigateBack}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit" 
                loading={loading[newHireActions.createNewHire.typePrefix] ||
                          loading[newHireActions.updateNewHire.typePrefix] ||
                          false}
                >
                Save
              </Button>
            </Space>
          </div>
          <Row gutter={[16, 12]}>
            <Col xs={24}>
              <Card>
                <Row gutter={[16, 12]}>
                  <Col xs={24} className="title">
                    Personal Infomation
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[16, 12]}>
                      <Col xs={24}>
                        <Form.Item
                          label="Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Select
                            className="w-100"
                            options={constantsToOptions(constants?.employeeTitles || [])}
                            placeholder="Select title"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Forename"
                          name="forename"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                            {
                              pattern: new RegExp(/^[a-zA-Z ]+$/i),
                              message: 'Please enter on alphabets only!',
                            },
                          ]}
                        >
                          <Input className="w-100" placeholder="Enter forename" maxLength={128} />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Surname"
                          name="surname"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                            {
                              pattern: new RegExp(/^[a-zA-Z ]+$/i),
                              message: 'Please enter on alphabets only!',
                            },
                          ]}
                        >
                          <Input placeholder="Enter surname" maxLength={128} />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Input placeholder="Enter email" type="email" disabled={!props?.create} maxLength={128} />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item label="Address" name="address">
                          <Input placeholder="Enter address" maxLength={128} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[16, 12]}>
                      <Col xs={24}>
                        <Form.Item
                          label="Date of birth"
                          name="dob"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                          getValueProps={(i) => ({
                            value: i ? dayjs(i) : '',
                          })}
                        >
                          <DatePicker disabledDate={disabledBirthDate} format={dateFormat} className="w-100 p-12" />
                        </Form.Item>
                      </Col>

                      <Col xs={24}>
                        <Form.Item name="employeeNationality" noStyle></Form.Item>
                        <Form.Item
                          label="Nationality"
                          name="employeeNationalityID"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select country"
                            filterOption={(input: string, option: any) => {
                              return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
                            }}
                            showSearch
                          >
                            {countries.map((item: any, index: number) => {
                              return (
                                <Select.Option key={item?.id} value={item?.id} name={item?.name} data={item}>
                                  <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                                  {item?.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Marital Status"
                          name="maritalStatus"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Select
                            className="w-100"
                            options={constantsToOptions(constants?.maritalStatuses || [])}
                            placeholder="Select marital status"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Phone Number"
                          name="phoneNumber"
                          rules={[
                            {
                              pattern: /^[0-9]+$/,
                              message: 'Please enter on numbers only.',
                            },
                          ]}
                        >
                          <Input placeholder="Enter phone number" maxLength={15} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24}>
              <Card>
                <Row gutter={[16, 12]}>
                  <Col xs={24} className="title">
                    Position Details
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[16, 12]}>
                      <Col xs={24}>
                        <Form.Item
                          label="Job Title"
                          name="jobTitle"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Input className="w-100" placeholder="Enter job title" maxLength={128} />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Job Description"
                          name="jobDescription"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Input.TextArea rows={3} className="w-100" placeholder="Enter job description" maxLength={5000} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[16, 12]}>
                      <Col xs={24}>
                        <Form.Item name="countryOfHire" noStyle></Form.Item>
                        <Form.Item
                          label="Country of Hire"
                          name="countryOfHireID"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select country"
                            filterOption={(input: string, option: any) => {
                              return option?.name?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
                            }}
                            showSearch
                          >
                            {countries.map((item: any, index: number) => {
                              return (
                                <Select.Option key={item?.id} value={item?.id} name={item?.name}>
                                  <img src={item?.flag} width="16" height="12" alt={`${item}`} className="mr-2" />
                                  {item?.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Work Type"
                          name="workType"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Select placeholder="Select work type" options={constantsToOptions(constants?.workTypes || [])} />

                          {/* <Radio.Group className="d-flex">
                            {constants?.workTypes.map((item: any) => (
                              <Radio className="w-50" value={item.value}>
                                {item?.name}
                              </Radio>
                            ))}
                          </Radio.Group> */}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  {/* <Col xs={12}>
                      <Form.Item
                        label="Client Name"
                        name="clientName"
                        rules={[
                          {
                            required: true,
                            message: "This field is required!",
                          },
                        ]}
                      >
                        <Input
                          className="w-100"
                          placeholder="Enter client name"
                        />
                      </Form.Item>
                    </Col> */}
                </Row>
              </Card>
            </Col>
            <Col xs={24}>
              <Card>
                <Row gutter={[16, 12]}>
                  <Col xs={24} className="title">
                    Payment
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[16, 12]}>
                      <Col xs={24}>
                        <Form.Item
                          label="Currency"
                          name="currency"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select currency"
                            options={constantsToOptions(constants?.currencies || [])}
                            filterOption={(input: string, option: any) => {
                              return option?.label?.toLowerCase?.()?.indexOf(input?.toLowerCase()) >= 0;
                            }}
                            showSearch
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Pay Rate"
                          name="payRate"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <InputNumber
                            // prefix="€"
                            step=".01"
                            placeholder="Enter pay rate"
                            className="w-100"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Pay Frequency"
                          name="payFrequency"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Select placeholder="Select pay frequency" options={constantsToOptions(constants?.payFrequencies || [])} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[16, 12]}>
                      <Col xs={24}>
                        <Form.Item
                          label="Payroll Type"
                          name="payrollType"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Select placeholder="Select payroll type" options={constantsToOptions(constants?.payrollTypes || [])} />

                          {/* <Radio.Group className="d-flex">
                            {constants?.workTypes.map((item: any) => (
                              <Radio className="w-50" value={item.value}>
                                {item?.name}
                              </Radio>
                            ))}
                          </Radio.Group> */}
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item label="Bonuses" name="bonuses">
                          <InputNumber
                            // prefix="€"
                            step=".01"
                            placeholder="Enter bonuses"
                            className="w-100"
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24}>
                        <Form.Item label="Allowances" name="allowances">
                          <Input.TextArea rows={3} placeholder="Enter allowances" className="w-100" maxLength={128} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24}>
              <Card>
                <Row gutter={[16, 12]}>
                  <Col xs={24} className="title">
                    Additional Infomation
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[16, 12]}>
                      <Col xs={24}>
                        <Form.Item
                          label="Contract Type"
                          name="contractType"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Select
                            value={contractType}
                            onChange={(e) => setContractType(e)}
                            placeholder="Select contract type"
                            options={constantsToOptions(constants?.contactTypes || [])}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Start date"
                          name="startDate"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                          getValueProps={(i) => ({
                            value: i ? dayjs(i) : '',
                          })}
                        >
                          <DatePicker
                            format={dateFormat}
                            className="w-100 p-12"
                            disabledDate={disabledStartDate}
                            onChange={(value) => setStartDate(dayjs(value).valueOf())}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="End date"
                          name="endDate"
                          rules={[
                            {
                              required: contractType === 'fixed',
                              message: 'This field is required!',
                            },
                          ]}
                          getValueProps={(i) => ({
                            value: i ? dayjs(i) : '',
                          })}
                        >
                          <DatePicker
                            format={dateFormat}
                            className="w-100 p-12"
                            disabledDate={disabledEndDate}
                            onChange={(value) => setEndDate(dayjs(value).valueOf())}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item label="Contractor Onboarding" name="contractor">
                          <Input placeholder="Enter contractor onboarding" className="w-100" maxLength={128} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row gutter={[16, 12]}>
                      <Col xs={24}>
                        <Form.Item
                          label="Does the employee need a visa?"
                          name="needVisa"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Radio.Group className="d-flex">
                            <Radio className="w-50" value={true}>
                              Yes
                            </Radio>
                            <Radio className="w-50" value={false}>
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          label="Do you require health insurance?"
                          name="requireHealthInsurance"
                          rules={[
                            {
                              required: true,
                              message: 'This field is required!',
                            },
                          ]}
                        >
                          <Radio.Group className="d-flex">
                            <Radio className="w-50" value={true}>
                              Yes
                            </Radio>
                            <Radio className="w-50" value={false}>
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col sm={24}>
                        <Form.Item label="Payroll System" name="payrollID">
                          <Select placeholder="Select payroll system " options={settingsToOptions(settings?.payrolls || [])} />
                        </Form.Item>
                      </Col>
                      <Col sm={24}>
                        <Form.Item
                          label="End Client"
                          name="endClientID"
                          // rules={[{ required: true, message: 'This field is required!' }]}
                        >
                          <SearchEndClients
                            showSuggest
                            onSelect={(value, record) => {
                              form.setFieldValue('endClientID', value);
                              form.setFieldValue('endClient', record);
                              console.log('record', record);
                            }}
                            data={form?.getFieldValue('endClient')?.name}
                            // key={`endClientID-${form?.getFieldValue("endClient")?.name}`}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24}>
              <Card>
                <Row gutter={[16, 12]}>
                  <Col xs={24} className="title">
                    Other Documents
                  </Col>
                  <Col xs={24}>
                    <Button ghost type="primary" onClick={() => handleOpenModal(true)}>
                      Upload File
                    </Button>
                  </Col>
                  <Form.Item noStyle name="documents"></Form.Item>

                  {documents?.length ? (
                    <Col xs={24}>
                      <div className="table-responsive">
                        <TableWithScrollButtons
                          columns={columns}
                          dataSource={documents}
                          size="small"
                          rowKey={(record: IDocument) => record?.id + record?.attachment?.fileURL || 'random'}
                          scroll={{ x: 1000, y: 370 }}
                          pagination={{
                            onChange: (page: number, pageSize: number) => handlePaginationChange(page, pageSize),
                            showSizeChanger: true,
                            defaultCurrent: 1,
                            total: documentPagination?.total,
                            position: ['bottomLeft'],
                            pageSizeOptions: pageSizeOptions,
                            showTotal: (total: number, range: number[]) =>
                              'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                          }}
                        />
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
        {isModalOpen && (
          <UploadDocumentModal
            open={isModalOpen}
            destroyOnClose={true}
            onCancel={() => setIsModalOpen(false)}
            onSubmit={handleSubmitDocument}
            countries={countries}
            hideCountryCode={true}
            hideDocumentType={true}
            confirmLoading={documentLoading}
          />
        )}
      </Spin>
    </div>
  );
};

export default NewHireForm;
