import api from '@/common/api';
import { FILTER_DEFAULT, SLICE_NAME } from '@/common/constants/stores';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ICreateImportResponse, IDocument, IDocumentQuery, IPagination, IResponsePagination } from '@/types/app';
import { navigateTo } from '@/utils/auth';
import { openNotification } from '@/utils';
import { ROUTE_PATH } from '@/common/constants/routes';
import { uploadS3 } from '@/common/api/s3';
import Axios from 'axios';
import config from '@/config';
import axios from 'axios';

export interface IImportsState {
  data: IDocument[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  // paginationSubData: IPagination;
  detail: IDocument | null;
  reviews: IDocument[];
  filter: IDocumentQuery;
  link: string;
}

export const initialState: IImportsState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  // paginationSubData: {
  //   total: 0,
  //   page: 0,
  //   limit: 10,
  //   totalPage: 0,
  // },
  data: [],
  loading: {},
  detail: null,
  reviews: [],
  filter: FILTER_DEFAULT,
  link: '',
};

const getDocuments = createAsyncThunk(`${SLICE_NAME.IMPORTS}/getDocuments`, async (query: IDocumentQuery, { rejectWithValue }) => {
  try {
    const data = (await api.getImports)<IResponsePagination<IDocument>>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const getImportTemplate = createAsyncThunk(`${SLICE_NAME.IMPORTS}/getImportTemplate`, async (_, { rejectWithValue }) => {
  try {
    const data = await api.getImportTemplate<any>();
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const getDocument = createAsyncThunk(
  `${SLICE_NAME.IMPORTS}/getDocument`,
  async (query: { id: string | undefined }, { rejectWithValue }) => {
    try {
      const data = await api.getImport<any>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getDocumentReview = createAsyncThunk(`${SLICE_NAME.IMPORTS}/getDocumentReview`, async (query: any, { rejectWithValue }) => {
  try {
    const data = await api.getImportReview<any>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const createDocument = createAsyncThunk(`${SLICE_NAME.IMPORTS}/createDocument`, async (form: IDocument, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
      formData.append('file', form.attachmentUpload);
    }
    const data = await (api.createImport)<ICreateImportResponse>(formData, { headers: { 'Content-Type': 'multipart/form-data' }, isBinaryFile: true });
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const updateDocument = createAsyncThunk(`${SLICE_NAME.IMPORTS}/updateDocument`, async (form: IDocument, { rejectWithValue }) => {
  try {
    const data = await api.updateImport<{ data: IDocument }>(form);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const deleteDocument = createAsyncThunk(`${SLICE_NAME.IMPORTS}/deleteDocument`, async (query: any, { rejectWithValue }) => {
  try {
    const data = await api.deleteImport<{ data: IDocument }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const importsSlice = createSlice({
  name: SLICE_NAME.IMPORTS,
  initialState: initialState,
  reducers: {
    setFilter(state, { payload }) {
      let newFilter = { ...payload };
      Object.keys(payload)?.filter((key) => {
        if (!payload?.[key]) {
          delete newFilter?.[key];
        }
      });
      state.filter = newFilter;
    },
    resetFilter(state) {
      state.filter = initialState.filter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocuments.fulfilled, (state, { payload }) => {
      state.loading[getDocuments.typePrefix] = false;
      state.data = payload.data.data;
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getDocuments.pending, (state, { payload }) => {
      state.loading[getDocuments.typePrefix] = true;
    });
    builder.addCase(getDocuments.rejected, (state, { payload }) => {
      state.loading[getDocuments.typePrefix] = false;
    });
    builder.addCase(getDocument.fulfilled, (state, { payload }) => {
      state.loading[getDocument.typePrefix] = false;
      state.detail = payload.data.data;
    });
    builder.addCase(getDocument.pending, (state, { payload }) => {
      state.loading[getDocument.typePrefix] = true;
    });
    builder.addCase(getDocument.rejected, (state, { payload }) => {
      state.loading[getDocument.typePrefix] = false;
    });
    builder.addCase(getDocumentReview.fulfilled, (state, { payload }) => {
      state.loading[getDocumentReview.typePrefix] = false;
      state.reviews = payload.data.data;
      // state.paginationSubData = payload.data.pagination;
    });
    builder.addCase(getDocumentReview.pending, (state, { payload }) => {
      state.loading[getDocumentReview.typePrefix] = true;
    });
    builder.addCase(getDocumentReview.rejected, (state, { payload }) => {
      state.loading[getDocumentReview.typePrefix] = false;
    });

    builder.addCase(getImportTemplate.fulfilled, (state, { payload }) => {
      state.loading[getImportTemplate.typePrefix] = false;
      console.log('go here', payload);
      state.link = payload.data.data.link;
    });
    builder.addCase(getImportTemplate.pending, (state, { payload }) => {
      state.loading[getImportTemplate.typePrefix] = true;
    });
    builder.addCase(getImportTemplate.rejected, (state, { payload }) => {
      state.loading[getImportTemplate.typePrefix] = false;
    });
    builder.addCase(updateDocument.fulfilled, (state, { payload }) => {
      // navigateTo(ROUTE_PATH.DOCUMENT_SUITE);
      state.loading[updateDocument.typePrefix] = false;
      openNotification('success', 'Update successfully!');
    });
    builder.addCase(updateDocument.pending, (state, { payload }) => {
      state.loading[updateDocument.typePrefix] = true;
    });
    builder.addCase(updateDocument.rejected, (state, { payload }) => {
      state.loading[updateDocument.typePrefix] = false;
    });

    builder.addCase(createDocument.fulfilled, (state, { payload }) => {
      state.loading[createDocument.typePrefix] = false;
      // openNotification('success', 'Uploaded document successfully!');
    });
    builder.addCase(createDocument.pending, (state, { payload }) => {
      state.loading[createDocument.typePrefix] = true;
    });
    builder.addCase(createDocument.rejected, (state, { payload }) => {
      // openNotification('error', 'Uploaded of document failed!');
      state.loading[createDocument.typePrefix] = false;
    });

    builder.addCase(deleteDocument.fulfilled, (state, { payload }) => {
      openNotification('success', `Import ${payload?.data?.data?.id} delete successfully!`);
      state.loading[deleteDocument.typePrefix] = false;
    });
    builder.addCase(deleteDocument.pending, (state, { payload }) => {
      state.loading[deleteDocument.typePrefix] = true;
    });
    builder.addCase(deleteDocument.rejected, (state, { payload }) => {
      openNotification('error', 'Delete failed!');
      state.loading[deleteDocument.typePrefix] = false;
    });
  },
});

export const importsReducer = importsSlice.reducer;
export const importsCaseReducers = importsSlice.caseReducers;
export const importsActions = {
  ...importsSlice.actions,
  getDocuments,
  getDocument,
  getImportTemplate,
  getDocumentReview,
  updateDocument,
  deleteDocument,
  createDocument,
};
