import { dateFormat } from '@/common/constants/format';
import { GrayEditIcon, SettingIcon } from '@/components/common/IconComponents';
import { IUser, IUserPermission } from '@/types/app';
import { Button, Space, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Paragraph from 'antd/es/typography/Paragraph';
import dayjs from 'dayjs';

export const getColumns = ({
  onSetPermissionOnProfile,
  onSetPermissionGroup,
  isMobile,
  userInfo,
}: {
  onSetPermissionGroup?: (item: IUser) => void;
  onSetPermissionOnProfile?: (item: IUser) => void;
  isMobile: boolean;
  userInfo?: IUser;
}): ColumnsType<IUser> => [
  {
    title: 'Actions',
    key: 'action',
    fixed: 'left',
    width: 100,
    align: 'center',
    sorter: false,
    render: (_, record) =>
      userInfo?.role === 'company' || record?.parentUserID ? (
        <Space size="middle">
          <Tooltip title="Set Group">
            <Button type="text" size="small" onClick={() => onSetPermissionGroup?.(record)}>
              <GrayEditIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Set Profile">
            <Button type="text" size="small" onClick={() => onSetPermissionOnProfile?.(record)}>
              <SettingIcon />
            </Button>
          </Tooltip>
        </Space>
      ) : null,
  },
  {
    title: 'Client',
    dataIndex: 'name',
    key: 'employee',
    width: 250,
    // fixed: isMobile ? false : 'left',
    sorter: true,
    render: (_, record) => (
      <div className="general-information-table">
        <p className="title">{record.name}</p>
        <p className="">{record.email ? record.email : ''}</p>
      </div>
    ),
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    width: 150,
    sorter: true,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    width: 200,
    sorter: true,
  },
  {
    title: 'Permission Group',
    dataIndex: 'permissionGroup',
    key: 'name',
    width: 200,
    sorter: true,
    render: (_, record) => {
      const permissionGroup = record.userPermission?.permissionGroup;
      return (
        <>
          {permissionGroup ? (
            <Paragraph
              ellipsis={{
                rows: 1,
                // expandable: false,
                // tooltip: permissionGroup.name,
              }}
              title={permissionGroup.name}
            >
              {permissionGroup.name}
            </Paragraph>
          ) : null}
        </>
      );
    },
  },
  {
    title: 'Updated By',
    dataIndex: 'updatedByUser',
    key: 'updatedByUser',
    width: 200,
    sorter: true,
    render: (_, record) => record?.userPermission?.updatedByUser?.name ?? 'N/A',
  },
  {
    title: 'Last Update',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 200,
    sorter: true,
    render: (_, record) =>
      record?.userPermission ? dayjs.unix(record.userPermission.updatedAt ?? record.userPermission.createdAt).format(dateFormat) : 'N/A',
  },
];
