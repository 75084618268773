import api from '@/common/api';
import { FILTER_DEFAULT, SLICE_NAME } from '@/common/constants/stores';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  IDocument,
  IDocumentQuery,
  IEmployee,
  IEmployeeResponse,
  IExportEmployeesResponse,
  IPagination,
  IReportQuery,
  IResponsePagination,
  UserRole,
} from '@/types/app';
import { IEmployeeColumnAndName } from '@/common/constants/columns';
import { uploadS3 } from '@/common/api/s3';
import { openNotification } from '@/utils';

export interface IReportsState {
  loading: { [key: string]: boolean };
  detail?: IEmployee;

  modalPreviewEmployeesCSVVisibility?: boolean;

  reviewEmployeesReport: IEmployee[];
  fields: IEmployeeColumnAndName[];
  data: IDocument[];
  pagination: IPagination;
  filter: IReportQuery;
}

export const initialState: IReportsState = {
  loading: {},
  detail: undefined,
  modalPreviewEmployeesCSVVisibility: false,
  reviewEmployeesReport: [],
  fields: [],
  data: [],
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  filter: { ...FILTER_DEFAULT },
};

const previewEmployeesCSV = createAsyncThunk(
  `${SLICE_NAME.REPORTS}/previewEmployeesCSV`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.previewEmployeesCSV<{ data: IEmployee[] }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const exportEmployeesCSV = createAsyncThunk(
  `${SLICE_NAME.REPORTS}/exportEmployeesCSV`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.exportEmployeesCSV<{
        data: IExportEmployeesResponse;
      }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getReports = createAsyncThunk(
  `${SLICE_NAME.REPORTS}/getReports`,
  async ({ query, role }: { query: IReportQuery; role?: UserRole }, { rejectWithValue }) => {
    try {
      const data = (await api.getReports)<IResponsePagination<IDocument>>({ ...query, role: role });
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createReport = createAsyncThunk(
  `${SLICE_NAME.REPORTS}/createReport`,
  async ({ form, role }: { form: IDocument; role?: UserRole }, { rejectWithValue }) => {
    try {
      if (form?.attachmentUpload && form?.attachmentUpload instanceof File) {
        const { success, data: attachment } = await uploadS3(form?.attachmentUpload, 'reports');
        if (success) {
          form = {
            ...form,
            attachment,
          };
        }
      }
      const data = await api.createReport<{ data: IDocument }>({ ...form, role: role });
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const reportsSlice = createSlice({
  name: SLICE_NAME.EMPLOYEE,
  initialState: initialState,
  reducers: {
    setFilter(state, { payload }) {
      let newFilter = { ...payload };
      Object.keys(payload)?.filter((key) => {
        if (!payload?.[key]) {
          delete newFilter?.[key];
        }
      });
      state.filter = newFilter;
    },
    resetFilter(state) {
      state.filter = initialState.filter;
    },
    onOpenModalPreviewCSV(state) {
      state.modalPreviewEmployeesCSVVisibility = true;
    },
    onCloseModalPreviewCSV(state) {
      state.modalPreviewEmployeesCSVVisibility = false;
    },
    onSetExportFields(state, { payload }) {
      state.fields = payload;
    },
    onResetReviewEmployeesReport(state) {
      state.reviewEmployeesReport = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(previewEmployeesCSV.fulfilled, (state, { payload }) => {
      state.loading[previewEmployeesCSV.typePrefix] = false;
      state.reviewEmployeesReport = payload.data.data;
    });
    builder.addCase(previewEmployeesCSV.pending, (state, { payload }) => {
      state.loading[previewEmployeesCSV.typePrefix] = true;
    });
    builder.addCase(previewEmployeesCSV.rejected, (state, { payload }) => {
      state.loading[previewEmployeesCSV.typePrefix] = false;
    });

    builder.addCase(exportEmployeesCSV.fulfilled, (state, { payload }) => {
      state.loading[exportEmployeesCSV.typePrefix] = false;
    });
    builder.addCase(exportEmployeesCSV.pending, (state, { payload }) => {
      state.loading[exportEmployeesCSV.typePrefix] = true;
    });
    builder.addCase(exportEmployeesCSV.rejected, (state, { payload }) => {
      state.loading[exportEmployeesCSV.typePrefix] = false;
    });
    builder.addCase(getReports.fulfilled, (state, { payload }) => {
      state.loading[getReports.typePrefix] = false;
      state.data = payload.data.data;
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getReports.pending, (state) => {
      state.loading[getReports.typePrefix] = true;
    });
    builder.addCase(getReports.rejected, (state) => {
      state.loading[getReports.typePrefix] = false;
    });
    builder.addCase(createReport.fulfilled, (state, { payload }) => {
      state.loading[createReport.typePrefix] = false;
      openNotification('success', 'Reports created successfully!');
    });
    builder.addCase(createReport.pending, (state, { payload }) => {
      state.loading[createReport.typePrefix] = true;
    });
    builder.addCase(createReport.rejected, (state, { payload }) => {
      openNotification('error', 'Create failed!');
      state.loading[createReport.typePrefix] = false;
    });
  },
});

export const reportsReducer = reportsSlice.reducer;
export const reportsCaseReducers = reportsSlice.caseReducers;
export const reportsActions = {
  ...reportsSlice.actions,
  previewEmployeesCSV,
  exportEmployeesCSV,
  getReports,
  createReport,
};
