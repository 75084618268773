import { IConstant, IConstants, IDocument, IEmployeeTimesheetsListTable, IResponsePagination } from '@/types/app';
import { Button, Form, Grid, Input, Space, Spin, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { EyeTwoTone } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import ViewTimesheetsModal from '../Modals/ViewTimesheetsModal';
import { currencyFormat, dateFormat, pageSizeOptions } from '@/common/constants/format';
import TableWithScrollButtons from '../Shared/TableWithScrollButtons';
import { downloadFileFromURL, exportToXLSX, getBreakPoint, matchValueToLabel } from '@/utils';
import dayjs from 'dayjs';
import { FileTwoTone, DownloadOutlined } from '@ant-design/icons';
import UploadDocumentModal from '../Modals/UploadDocumentModal';
import { useDispatch, useSelector } from '@/stores';
import { useParams } from 'react-router-dom';
import { employeeActions } from '@/stores/employee';
import { DownloadIcon, EyeIcon, SearchIcon } from '../common/IconComponents';
import { isEmployee } from '@/utils/auth';
import { SorterResult } from 'antd/es/table/interface';

interface IProps {
  data?: IResponsePagination<IDocument>;
  constants?: IConstants;
  onImport?: () => void;
  documentType?: string;
}

const { useBreakpoint } = Grid;

const EmployeeDocuments = (props: IProps) => {
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');
  const { data, onImport, documentType } = props;

  const { constants } = useSelector((state) => state?.app);
  const { userInfo } = useSelector((state) => state.auth);
  const { uploadDocumentType, loading } = useSelector((state) => state.employee);

  const { id } = useParams();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');

  const dispatch = useDispatch();

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    console.log('handleSearchSubmit',e)
    setSearchText(keyword);
    if (documentType === "timesheet")
      dispatch(
        employeeActions.getEmployeeTimesheets({
          role: userInfo?.role,
          id,
          keyword,
        })
      );
    else if (documentType === "payslip")
      dispatch(
        employeeActions.getEmployeePayslips({
          role: userInfo?.role,
          id,
          keyword,
        })
      );
    else if (documentType === "others")
      dispatch(
        employeeActions.getEmployeeOthers({
          role: userInfo?.role,
          id,
          keyword,
        })
      );
    else if (documentType === "guide")
      dispatch(
        employeeActions.getEmployeeGuides({
          role: userInfo?.role,
          id,
          keyword,
        })
      );
    // dispatch(userActions.getUsers({ keyword }));
  };

  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IDocument> | SorterResult<IDocument>[],
  ) => {
    if (documentType === "timesheet")
      dispatch(
        employeeActions.getEmployeeTimesheets({
          role: userInfo?.role,
          id,
          keyword: searchText,
          page: pagination.current,
          limit: pagination.pageSize,
          sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
        }),
      );
    else if (documentType === "payslip")
      dispatch(
        employeeActions.getEmployeePayslips({
          role: userInfo?.role,
          id,
          keyword: searchText,
          page: pagination.current,
          limit: pagination.pageSize,
          sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
        })
      );
    else if (documentType === "others")
      dispatch(
        employeeActions.getEmployeeOthers({
          role: userInfo?.role,
          id,
          keyword: searchText,
          page: pagination.current,
          limit: pagination.pageSize,
          sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
        })
      );
    else if (documentType === "guide")
      dispatch(
        employeeActions.getEmployeeGuides({
          role: userInfo?.role,
          id,
          keyword: searchText,
          page: pagination.current,
          limit: pagination.pageSize,
          sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
        })
      );
  };

  const columns: ColumnsType<IDocument> = [
    {
      title: 'Actions',
      key: 'action',
      fixed: 'left',
      width: 120,
      align: 'center',
      render: (_, record) => (
        record?.attachment?.fileURL?<Space size="middle">
          <Tooltip title="Open Document">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                window.open(record?.attachment?.fileURL);
              }}
            >
              <EyeIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download">
            <Button
              htmlType="button"
              className="btn btn-icon d-flex align-items-center"
              onClick={() => {
                downloadFileFromURL(record?.attachment?.fileURL, record?.attachment?.metadata?.name);
              }}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        </Space>:null
      ),
    },
    {
      title: 'File Name',
      dataIndex: ['attachment', 'metadata', 'name'],
      key: 'fileName',
      align: 'center',
    },
    {
      title: 'Date Uploaded',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (val) => dayjs.unix(val).format(dateFormat),
    },
    // {
    //   title: "Document Type",
    //   dataIndex: "type",
    //   key: "type",
    //   align: "center",
    //   render: (val) => matchValueToLabel(constants?.documentTypes || [], val),
    // },
    {
      title: documentType==="guide"?"Title":'Description',
      dataIndex: documentType==="guide"?"title":'description',
      key: 'description',
      align: 'center',
      render: (val) => val,
    },
  ];

  const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: IEmployeeTimesheetsListTable[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleOpenModal = (e: boolean) => {
    onImport && onImport();
  };


  return (
    <Spin
      tip="Loading"
      size="large"
      spinning={
        loading[employeeActions.getEmployeeGuides.typePrefix] ||
        loading[employeeActions.getEmployeeOthers.typePrefix] ||
        loading[employeeActions.getEmployeePayslips.typePrefix] ||
        loading[employeeActions.getEmployeeTimesheets.typePrefix] ||
        false
      }
    >
      <div className="d-flex justify-content-between p-2">
        <Input
          prefix={<SearchIcon className="mr-0" />}
          placeholder="Search"
          onPressEnter={(e: any) => {
            e.preventDefault();
            handleSearchSubmit(e?.target?.value);
          }}
          className="w-100 mr-2 page-listing-search-input"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
        {/* <Button onClick={() => handleExport()} type="primary">
            Export
          </Button>
          <Button onClick={() => handleExportAll()} type="primary">
            Export All
          </Button> */}
        {onImport && !isEmployee() && (
          <Button type="primary" onClick={() => handleOpenModal(true)}>
            Import
          </Button>
        )}
      </div>
      <div className="bg-white rounded">
        <div className="card-body">
          <div className="table-responsive">
            <TableWithScrollButtons
              columns={columns}
              dataSource={data?.data}
              size="small"
              // rowSelection={rowSelection}
              rowKey={(record: IEmployeeTimesheetsListTable) => record.id || 'random'}
              scroll={{ x: 1200, y: 370 }}
              onChange={(pagination, filter, sorter) => {
                handleSortAndPaginationChange(pagination, sorter);
              }}
              pagination={{
                showSizeChanger: true,
                defaultCurrent: 1,
                total: data?.pagination?.total,
                position: ['bottomLeft'],
                pageSizeOptions: pageSizeOptions,
                showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
              }}
            />
          </div>
        </div>
      </div>
      <ViewTimesheetsModal isOpen={isViewModalOpen} setIsOpen={(val: boolean) => setIsViewModalOpen(val)} />
    </Spin>
  );
};

export default EmployeeDocuments;
